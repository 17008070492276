(function(global, $) {
    'use strict';

    const form = function() {
        /**
         * Handle asynchronous form loading / submission
         */
        function loadForm ($form, method) {
            $.ajax({
                url: $form.attr('action'),
                method: method,
                data: $form.serialize()
            })
                // done() is triggered whatever the number of errors in the form
                .done(function (data) {
                    var $data = $(data);
                    var isFormValid = parseInt($data.attr('data-valid'), 10);

                    if (1 === isFormValid && 'POST' === method) {
                        // form valid: reload the page (it will trigger notices and display the new survey in the list)
                        document.location.reload();
                    } else {
                        // form invalid: replace it with the one that contains errors
                        $form.replaceWith($data);
                    }
                })
        }

        function addModalFormBehavior()
        {
            $('[data-toggle="modal"].modal-form').each(function () {
                var $btn = $(this);
                var modalId = $btn.attr('data-target');

                // Modal opening: loads the empty form (GET method, see the related controller)
                $btn.on('click', function () {
                    var $form = $(modalId + ' form');

                    loadForm($form, 'GET');
                });

                // Form submission: submit the form (POST method, see the related controller)
                $('body').on('submit', modalId + ' form', function (e) {
                    e.preventDefault();

                    var $form = $(e.currentTarget);

                    loadForm($form, 'POST');
                });
            });
        }

        function init() {
            /**
             * Show / hide toggle button for passwords
             */
            $('.password-toggle').on('click', function (e) {
                e.preventDefault();

                const $input = $(this).parentsUntil('.form-group').parent().find('input');

                // Switch icons
                $(this).children().toggleClass('d-none');

                // Switch input type
                $input.attr('type', $input.attr('type') === 'password' ? 'text' : 'password');
            });

            addModalFormBehavior();

            /**
             * Add click event on share survey button
             */
            $('button.share-survey').on('click', shareModalOpen);
        }

        /**
         * Open modal
         * @param e
         */
        function shareModalOpen(e) {
            e.preventDefault();

            // Get modal
            var $modal = $('.modal-share-survey');

            // Ajax call
            $.ajax({
                url: $(this).data('target'),
            })
            .done(function (data) {
                // Add modal content
                $modal.replaceWith(data);
                $modal = $('.modal-share-survey');
                // Show modal
                $modal.modal("show");
                // Add submit event on form
                $modal.find('form[name="share_survey"]').on('submit', shareModalSubmit);
            });
        }

        /**
         * Submit form modal and replace modal content
         * @param e
         */
        function shareModalSubmit(e) {
            e.preventDefault();

            // Get modal
            var $modal = $('.modal-share-survey');

            // Ajax call
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize()
            })
            .done(function (data) {
                // Hide modal
                $modal.modal("hide");
                // Replace modal
                $modal.replaceWith(data);
                $modal = $('.modal-share-survey');
                // Show modal
                $modal.modal("show");
                // Add submit event on form
                $modal.find('form[name="shared_survey"]').on('submit', shareModalSubmit);
            });
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = form;
    }

})(window, window.jQuery);

