(function(global, $) {
    'use strict';

    const pedagogical = function() {

        const nbItemsDisplayed = {
                chronological: 3,
                category: 4
            },
            slickConfig = {
                infinite: false,
                slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            infinite: true,
                            dots: true
                        }
                    }
                ]
            };


        function init() {

            if ($('body').hasClass('pedagogical-tools')) {

                nbItemsDisplayed.chronological = parseInt($('.js-pedagogical-items[data-type=chronological]').data('nbdisplayed'));
                nbItemsDisplayed.category = parseInt($('.js-pedagogical-items[data-type=category]').data('nbdisplayed'));

                // init slider chronological
                const $chronologicalTabSlider = $('.js-pedagogical-category-slider[data-type=chronological]');
                $chronologicalTabSlider.slick(slickConfig);
                $chronologicalTabSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                    callbackTabClicked($('.js-pedagogical-tab[data-type=chronological][data-tab=' + nextSlide + ']'));
                });
                // init slider category
                const $categoryTabSlider = $('.js-pedagogical-category-slider[data-type=category]');
                slickConfig.slidesToShow = 5;
                $categoryTabSlider.slick(slickConfig);
                $categoryTabSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                    callbackTabClicked($('.js-pedagogical-tab[data-type=category][data-tab=' + nextSlide + ']'));
                });

                // Init items display for category chronological
                $('.js-pedagogical-items [data-type=chronological][data-value=before] .pedagogical__item__wrapper').slice(nbItemsDisplayed.chronological).hide();
                $('.js-pedagogical-items [data-type=chronological]:not([data-value=before])').hide();

                // Init items display for category question
                $('.js-pedagogical-items [data-type=category][data-value="1"] .pedagogical__item__wrapper').slice(nbItemsDisplayed.category).hide();
                $('.js-pedagogical-items [data-type=category]:not([data-value="1"])').hide();

                // hide 'show more' button for the chronological active tab if all items are displayed
                toggleShowMoreButton('chronological', 'before');


                // Handle click on category tab
                $('.js-pedagogical-tab').on('click', function() {
                    callbackTabClicked($(this));
                });

                // Handle click on 'show more' button
                $('.js-show-more').on('click', function() {

                    const categoryType = $(this).data('type'),
                        categoryValue = $(this).data('value');
                    var $showMoreButton = $(this);
                    var $next_pedagogical__items= $showMoreButton.parents('.pedagogical__items').next('.pedagogical__items');
                     if($next_pedagogical__items.length > 0 && $next_pedagogical__items.find('.pedagogical__item').length > 0){
                        $showMoreButton.hide();
                        $next_pedagogical__items.show();
                        $next_pedagogical__items.find('.js-show-more').show();
                    }else{
                        $showMoreButton.hide();
                    }
                    // Show the 3 next item
                    //$('.js-pedagogical-items [data-type=' + categoryType + '][data-value=' + categoryValue + '] .pedagogical__item__wrapper').slice(0, nbItemsDisplayed[categoryType]).fadeIn(300);

                    //toggleShowMoreButton(categoryType, categoryValue);
                });
                $( "div.pedagogical__items" ).each(function( index ) {
                    var articleCount= $(this).find('.pedagogical__item').length;
                    if(articleCount == 0){
                        // remove tab
                        $(this).find('.js-show-more').remove();
                    }

                    // remove next item btn
                    if($(this).next('.pedagogical__items').length > 0){
                        var $next_pedagogical__items=$(this).next('.pedagogical__items');
                        var articleNextCount= $next_pedagogical__items.find('.pedagogical__item').length;
                        console.log('Next===>' +articleNextCount)
                        if(articleNextCount==0 ){
                            $(this).find('.js-show-more').remove();
                        }
                    }
                });
                // remove tabs
                $( "div.pedagogical--category .pedagogical__items" ).each(function( index ) {
                    var articleCount= $(this).find('.pedagogical__item').length;
                    if(articleCount == 0){
                        // remove tab
                        var __id=  $( this ).attr('id');
                       $('#'+__id+'_category').remove();
                    }



                });
            }
        }

        function callbackTabClicked($tabElement) {

            const categoryType = $tabElement.data('type'),
                categoryValue = $tabElement.data('value'),
                $itemsToHide = $('.js-pedagogical-items[data-type=' + categoryType + '] .pedagogical__items'),
                $itemsToShow = $('.js-pedagogical-items[data-type=' + categoryType + '] [data-value=' + categoryValue + ']');

            toggleActiveTableCateogry(categoryType, categoryValue);

            $itemsToShow.find('.pedagogical__item__wrapper').slice(nbItemsDisplayed[categoryType]).hide();

            $itemsToHide.fadeOut(300, function() {
                setTimeout(function() {
                    $itemsToShow.fadeIn(300);
                }, 300);
            });
        }

        function toggleActiveTableCateogry(categoryType, categoryValue) {

            $('.js-pedagogical-tab[data-type=' + categoryType + ']').removeClass('active');
            $('.js-pedagogical-tab[data-type=' + categoryType + '][data-value=' + categoryValue + ']').addClass('active');
        }

        function toggleShowMoreButton(categoryType, categoryValue) {
            const $showMoreButton = $('.js-show-more[data-type=' + categoryType + '][data-value=' + categoryValue + ']');

            if ($('.js-pedagogical-items [data-type=' + categoryType + '][data-value=' + categoryValue + '] .pedagogical__item__wrapper').length == 0) {
                $showMoreButton.hide();
            } else {
                $showMoreButton.show();
            }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = pedagogical;
    }

})(window, window.jQuery);
