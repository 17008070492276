(function(global, $) {
    'use strict';

    const folder = function() {

        function init() {

            if($('.js-summary-list').length && $('js-summary-content')) {

                const $summaryList = $('.js-summary-list'),
                    $summaryContent = $('.js-summary-content'),
                    scrollValues = [];

                let counter = 0;
                $summaryContent.find('h3').each(function() {

                    const scrollValue = $(this).offset().top;
                    scrollValues.push(scrollValue);

                    let isActive = '';
                    if (counter === 0) {
                        isActive = 'active';
                    }

                    $summaryList.append('<li class="' + isActive + '" data-count="' + counter + '" data-scroll="' + scrollValue + '">' + $(this).html() + '</li>');
                    counter++;
                });

                // if there is no titles, remove summary wrapper
                if (counter === 0) {
                    $summaryList.remove();
                }

                $summaryList.find('li').on('click', function() {
                    const $targetedElem = $summaryContent.find('h3:eq('+ $(this).data('count') +')');

                    $([document.documentElement, document.body]).animate({
                        scrollTop: $targetedElem.offset().top
                    }, 500);
                });

                $(window).on('scroll', function() {
                    const currentScroll = $(window).scrollTop();

                    scrollValues.forEach(function(scrollValue) {
                        if (currentScroll >= scrollValue) {
                            $summaryList.find('li').removeClass('active');
                            $summaryList.find('li[data-scroll=' + scrollValue + ']').addClass('active');
                        }
                    });
                });
            }

        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = folder;
    }

})(window, window.jQuery);
