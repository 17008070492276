(function(global, $) {
    'use strict';
    window.chartColors = {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: 'rgb(255, 205, 86)',
        green: 'rgb(75, 192, 192)',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(231,233,237)'
    };

    var color = Chart.helpers.color;
    // Bar graph template
    const $template = $('.graph-bar.template');
    // Spider chart canvas
    const $chart = $('.spider-chart');
    // Container for bar graphs
    const $graphBarContainer = $('.graph-bar-container');

    const data = $chart.data('chart');
    const categories = [],
        answersThink = [],
        answersDo = [];
    // Total height of progress bar, used for calculations to fill depending on values
    const h = $template.find('.graph-bar__bars__bar--do').outerHeight();
    const categoryLabels = $chart.data('categories');
    const gaps = $chart.data('gaps');
    // Prevent carousel bug on first init
    let firstInitDone = false;

    const xAxisLabel = $('.question-stats-container').data('x-axis-label');
    const yAxisLabel = $('.question-stats-container').data('y-axis-label');

    const stats = function() {
        function init() {
            const body = $('body');

            if (body.hasClass('stats') || body.hasClass('survey-conclusion')) {
                $('.js-level').select2();
                const $jsSurveys = $('.js-surveys').select2({
                    multiple: true,
                    allowClear: true,
                    placeholder:  $('select#level-filter').data('placeholder')
                });
                $('.js-gender').select2();

                /*$jsSurveys.on('select2:selecting', function(){
                    const $allSurveys = $jsSurveys.find('[value="0"]:selected');
                    const $otherSurveys = $jsSurveys.find(':selected').not('[value="0"]');
                    if($allSurveys.length){
                        $allSurveys.prop('selected', false);
                    }

                    if($otherSurveys.length){
                        $otherSurveys.prop('selected', false);
                    }
                });*/
                if (typeof(data) !== 'undefined') {
                    for (let i = 0; i < data.length; i++) {
                        categories.push([categoryLabels[data[i].category], gaps[i]]);
                        answersThink.push(data[i].answerThink);
                        answersDo.push(data[i].answerDo);
                    }
                }

                if ($chart.length > 0) {
                    spider($chart, categories, answersThink, answersDo);
                }

                bar();

                $('.stats-themes__dropdown-item').on('click', function() {
                    $('.stats-themes__dropdown-item').removeClass('d-none');
                    $(this).addClass('d-none')
                    $(this).parents('.dropdown').find('.btn').html($(this).text());

                    const href = $(this).attr('href');

                    $('.stats-themes__nav-link').removeClass('active');
                    $('.stats-themes__nav-link[href="'+ href +'"]').addClass('active');

                    $('.stats-themes__tab-pane').removeClass('show active');
                    $(href).addClass('show active');
                });
            }
        }

        /**
         * @param {Element} chart
         * @param categories
         * @param answersThink
         * @param answersDo
         */
        function spider(chart, categories, answersThink, answersDo) {
            new Chart(chart, {
                type: 'radar',
                data: {
                    labels: categories,
                    datasets: [
                        {
                            data: answersThink,
                            backgroundColor: color(window.chartColors.yellow).alpha(0.2).rgbString(),
                            borderColor: window.chartColors.yellow,
                            pointBackgroundColor: window.chartColors.yellow,
                        },
                        {
                            data: answersDo,
                            backgroundColor: color(window.chartColors.orange).alpha(0.2).rgbString(),
                            borderColor: window.chartColors.orange,
                            pointBackgroundColor: window.chartColors.orange,
                        }
                    ]
                },
                options: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                    scale: {
                        ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                            max: 3
                        },
                        pointLabels:{
                            fontSize: 14
                        },
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false
                            },
                            gridLines: {
                                display: false,
                                drawBorder: false
                            },
                        }],
                        yAxes: [{
                            ticks: {
                                display: false
                            },
                            gridLines: {
                                display: false,
                                drawBorder: false
                            },
                        }]
                    }
                }
            });
        }

        /**
         * Create bar graphs for every question
         */
        function bar() {
            const charts = [];

            $('.graph-question').each(function() {
                var dataType = $(this).data('type');
                var colors = {
                    think: window.chartColors.yellow,
                    do: window.chartColors.orange
                };
                var labels = $(this).data('answers-label');
                var data = $(this).data('answers-'+dataType);

                const options = {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                data: data,
                                backgroundColor: colors[dataType]
                            }
                        ]
                    },
                    options: {
                        scaleShowValues: true,
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    autoSkip: false,
                                    maxTicksLimit: 9999,
                                    minRotation: 45,
                                    maxRotation: 45
                                },
                                gridLines: {
                                    display: false,
                                    drawBorder: false
                                },
                                scaleLabel: {
                                    display: false,
                                    labelString: xAxisLabel,
                                    padding: {
                                        'top': 20,
                                        'bottom': 0
                                    },
                                    fontSize: 9,
                                    lineHeight: 2
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    max: 100,
                                    min: 0,
                                    stepSize: 10,
                                    autoSkip: false,
                                    callback: function(label) {
                                        return label + '%';
                                    }
                                },
                                gridLines: {
                                    // You can change the color, the dash effect, the main axe color, etc.
                                    borderDash: [8, 4]
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: yAxisLabel.toLowerCase(),
                                    padding: {
                                        'top': 20,
                                        'bottom': 20
                                    },
                                    fontSize: 18
                                }
                            }]
                        },
                        animation: {
                            onComplete : function(){
                                //var url = barChart.toBase64Image(); //get image as base64

                            }
                        },
                        tooltips: {
                            callbacks: {
                                label: function(item, data) {
                                    var datasetLabel = data.datasets[item.datasetIndex].label || "";
                                    var dataValue = item.yLabel;
                                    return dataValue + "%";
                                }
                            }
                        }
                    }
                };

                const chart = new Chart($(this), options);
                charts.push(chart);
             });

            // Then, show only first pane (bug if creating charts in hidden elements)
            $('.stats-themes__tab-content').find('.tab-pane:not(":first-child")').removeClass('show active');

            $(window).on('resize', function() {
                updateBarCharts(charts, window.innerWidth < 768);
            });
        }

        /**
         *
         * @param {Array} charts
         * @param {boolean} isMobile
         */
        function updateBarCharts(charts, isMobile) {
            if (isMobile) {
                for (let i = 0; i < charts.length; i++) {
                    charts[i].options.scales.yAxes[0].ticks.stepSize = 20;
                    charts[i].options.scales.yAxes[0].scaleLabel.display = false;
                    charts[i].options.scales.xAxes[0].scaleLabel.display = false;
                    charts[i].update();
                }
            } else {
                for (let i = 0; i < charts.length; i++) {
                    charts[i].options.scales.yAxes[0].ticks.stepSize = 10;
                    charts[i].options.scales.yAxes[0].scaleLabel.display = true;
                    charts[i].options.scales.xAxes[0].scaleLabel.display = true;
                    charts[i].update();
                }
            }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = stats;
    }

})(window, window.jQuery);
