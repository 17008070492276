(function(global, $) {
    'use strict';

    const tips = function() {

        function init() {

            //~ if ($('.survey-question').length || $('body').hasClass('stats') || $('body').hasClass('survey-conclusion')) {

                $('.js-tips-open').on('click', function() {

                    const $parentElem = $(this).parent().parent();
                    if ($parentElem.hasClass('active')) {
                        $parentElem.removeClass('active');
                        $(this).find('.tips__open--active').hide('slow');
                        $(this).find('.tips__open--inactive').show('slow');
                    } else {
                        $parentElem.addClass('active');
                        $(this).find('.tips__open--inactive').hide('slow');
                        $(this).find('.tips__open--active').show('slow');
                    }
                });
            //~ }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = tips;
    }

})(window, window.jQuery);

