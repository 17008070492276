(function(global, $) {
    'use strict';

    const userSearch = function() {

        function submitSearch() {
            window.location = Routing.generate('admin_professional_list', {
                page : 1,
                search: $('.js-input-search').val()
            });
        }

        function init() {
            $('.js-search-valid').on('click', function(){
                submitSearch();
            });
            $('input').keypress(function(e){
                if( e.which == 13 ){
                    submitSearch();
                }
            });
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = userSearch;
    }

})(window, window.jQuery);
