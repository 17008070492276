(function (api, $) {
    'use strict';
    api.writeText = function (x, y, text, options) {
        options = options || {};

        var defaults = {
            align: 'left',
            width: this.internal.pageSize.width
        }

        var settings = $.extend({}, defaults, options);

        // Get current font size
        var fontSize = this.internal.getFontSize();

        // Get the actual text's width
        /* You multiply the unit width of your string by your font size and divide
         * by the internal scale factor. The division is necessary
         * for the case where you use units other than 'pt' in the constructor
         * of jsPDF.
        */
        var txtWidth = this.getStringUnitWidth(text) * fontSize / this.internal.scaleFactor;

        if (settings.align === 'center')
            x += (settings.width - txtWidth) / 2;
        else if (settings.align === 'right')
            x += (settings.width - txtWidth);

        //default is 'left' alignment
        this.text(text, x, y);

    }
})(jsPDF.API, jQuery);
(function(global, $) {
    'use strict';

    const survey = function() {

        const VALUE_NULL = -1,
            TYPE_DO = 'do',
            TYPE_THINK = 'think';

        function init() {
            if($('#survey_zipcode').length){
                initSurveyAutocomplete();
            }

            if ($('.survey-question').length) {

                $('.js-question-answer-item').on('click', function() {

                    const questionId = $(this).data('question');

                    if ($('.js-question-' + questionId + '-null').is(':not(:checked)')) {
                        setAnswerActive(
                            questionId,
                            $(this).data('questiontype'),
                            $(this).data('answer'),
                            $(this).data('value')
                        );
                    }
                });

                $('.js-question-null').on('change', function() {

                    const questionId = $(this).data('question');

                    if ($(this).is(':checked')) {
                        setAnswerAsNull(questionId);
                        toggleDisplayAnswers(questionId, false);
                    } else {
                        clearAnswer(questionId);
                        toggleDisplayAnswers(questionId, true);
                    }
                });

                $('.js-question-answers-type2').on('change', function() {

                    const questionId = $(this).data('question');

                    if ($('.js-question-' + questionId + '-null').is(':not(:checked)')) {
                        setAnswerActive(
                            questionId,
                            $(this).data('questiontype'),
                            $(this).data('answer'),
                            $(this).data('value')
                        );
                    } else {
                        $(this).prop('checked', false);
                    }
                });

                // handler question next animation end
                const questionNextAnimationEnd = (function(el) {
                    const animations = {
                        animation: 'animationend',
                        OAnimation: 'oAnimationEnd',
                        MozAnimation: 'mozAnimationEnd',
                        WebkitAnimation: 'webkitAnimationEnd',
                    };
                    for (const t in animations) {
                        if (el.style[t] !== undefined) {
                            return animations[t];
                        }
                    }
                })(document.createElement('div'));

                $('.js-question-next').on('click', function() {

                    const questionId = $(this).data('question'),
                        $questionNext = $('.js-question-' + questionId).next('.js-question');

                    $questionNext.removeClass('d-none').one(questionNextAnimationEnd, function () {
                        $('html,body').animate({
                            scrollTop: $questionNext.offset().top - 120
                        }, 1000);
                    });
                });

                // handle range selected value
                $('.js-range').on('input change', function() {

                    rangeChange($(this));
                });

                // init range values
                $('.js-range').each(function() {
                    if ($(this).is(':visible')) {
                        rangeChange($(this));
                    }
                });
            }

            $('#survey-create').on('shown.bs.modal', function (e) {

                if($('#survey_zipcode').length > 0) {
                    setTimeout(function(){
                        initSurveyAutocomplete();
                    }, 1000);
                }
            });
            $('.print-graph-question').on('click', function() {

                var doc = new jsPDF('p', 'pt', 'a4', false) /* Creates a new Document*/
                var width = doc.internal.pageSize.width;
                doc.setProperties({
                    title: 'Statistiques - FilGood',
                    subject: 'Statistiques',
                    author: 'FilGood',
                    keywords: 'Statistiques, FilGood, éducation',
                    creator: 'FilGood'
                });
                doc.setFontSize(12);
                var yAxis = 30;

                var category_id =$(this).data('category-id');

                // spider-chart
                var $spider_chart = $('canvas.spider-chart').get(0);
                //creates image
                var spider_chartImg = $spider_chart.toDataURL("image/png", 1.0);
                doc.addImage(spider_chartImg, 'png', 40, yAxis, 530, 300, undefined, 'none');
                doc.addPage();  /* Adds a new page*/
                var $category = $('#'+category_id);
                var category_label = $category.data('category-label');
                $category.find("canvas").each(function(i) {
                    var canvas = $(this).get(0);
                    var label = $(this).data('question-label');
                    var order = $(this).data('question-order');

                    if (i % 2 == 0 && i != 0) { /* I want only two images in a page */
                        doc.setPage(i);
                        doc.addPage();  /* Adds a new page*/
                        yAxis = 30; /* Re-initializes the value of yAxis for newly added page*/
                    }

                    if(typeof label != 'undefined') {

                        doc.setFont("helvetica");
                        doc.setFontType("bold");
                        var question = ' QUESTION ' + '#' + order;
                        doc.setFontSize(12);
                        doc.setTextColor(21, 150, 212);
                        doc.writeText(20, yAxis ,question, { align:'center' });
                        //Separate ligne

                        yAxis = yAxis + 20;
                        doc.setFont("times");
                        doc.setFontType("italic");
                        doc.setFontSize(11);
                        doc.setTextColor(102, 102, 102);
                        //doc.text(20, yAxis, label);

                        doc.writeText(20, yAxis ,label, { align:'center'});
                        yAxis = yAxis + 30; /* Update yAxis */
                        //creates image
                        var canvasImg = canvas.toDataURL("image/png", 1.0);
                        doc.addImage(canvasImg, 'png', 40, yAxis, 530, 300, undefined, 'none');
                        yAxis = yAxis + 320;

                        doc.setFont("helvetica");
                        doc.setFontType("bold");
                        doc.setFontSize(8);

                        doc.setTextColor(255, 205, 86);
                        doc.text(100,yAxis," Ce que les élèves font réellement");

                        doc.setTextColor(255, 159, 64);
                        doc.text(300,yAxis,"Ce que les élèves pensent");
                        if (i % 2 == 0) {
                            yAxis = yAxis + 10;
                            doc.line(0, yAxis + 10, width, yAxis + 10);
                            doc.setLineWidth(1);
                            yAxis = yAxis + 30;
                        }


                    }
                });
                doc.save(category_label+'.pdf');
            });

            // Ajax filter: replace the surveys filter <select> options when the level or theme filters changes
            const $selectFilterSurveys = $('select#filter-surveys');
            const $selectFilterLevel = $('select#filter-level');
            const $selectFilterTheme = $('select#filter-theme');

            if ($selectFilterSurveys.length === 1 && $selectFilterLevel.length === 1 && $selectFilterTheme.length === 1) {
                $('select#filter-level, select#filter-theme').on('change', function(e) {
                    e.preventDefault();

                    $.ajax({
                        type: 'POST',
                        url: $selectFilterLevel.attr('data-action'),
                        dataType: 'html',
                        data: {
                            level: $selectFilterLevel.val(),
                            theme: $selectFilterTheme.val(),
                        }
                    })
                        .done(function (data) {
                            $selectFilterSurveys.html(data);
                            $selectFilterSurveys.select2({
                                multiple: true,
                                allowClear:true,
                                placeholder: $('select#filter-level').data('placeholder')
                            });
                        });
                });
            }
         }


        function initSurveyAutocomplete() {

            //var survey_address =  document.getElementById('survey_zipcode');
            //var autocomplete = new google.maps.places.Autocomplete(
            //    /** @type {!HTMLInputElement} */
            //    (document.getElementById('survey_zipcode')), {
            //        types: ['geocode'],
            //        componentRestrictions: {country: "fr" }
            //    });
            //google.maps.event.addListener(autocomplete, 'place_changed', function() {
            //    var place = autocomplete.getPlace();
            //    if (place && place.address_components) {
            //        var address_components = place.address_components;
            //        var zip_code = address_components[0].long_name;
            //        var locality = address_components[1].long_name;
            //        var city = address_components[2].long_name;
            //        var country = address_components[3].long_name;
            //        $('#survey_zipcode').val(zip_code);
            //        $('#survey_city').val(locality);
            //    }
            //});

            //@FIXME : disable google maps autocomplete
            return false;
        }
        // Set answer as active :
        // - disable other answer of the same question and the same type (do/think) as inactive
        // - set clicked answer as active
        // - store the value in a hidden input
        function setAnswerActive(questionId, questionType, answerId, value) {

            // set all answers from current question as inactive
            setAnswerInactive(questionId, questionType);

            // set clicked answer as active
            $('.js-question-answer-' + answerId).addClass('active');

            if (questionType === TYPE_DO) {
                $('.js-question-' + questionId + '-label-mobile span').hide();
                $('.js-question-' + questionId + '-label-mobile span[data-value=' + value + ']').show();
            }

            // save answer value in hidden input
            storeAnswerValue(questionId, questionType, value);
        }

        // Set all answers for a question and its type (do/think) as inactive
        function setAnswerInactive(questionId, questionType) {
            $('.js-question-' + questionId).find('.js-question-' + questionType + ' .js-question-answer-item').removeClass('active');

            if (questionType == TYPE_DO) {
                $('.js-question-' + questionId + '-label-mobile span').hide();
            }
        }

        // Save answer value for a question and its type (do/think) in a hidden input
        function storeAnswerValue(questionId, questionType, value) {
            $('.js-question-' + questionId + '-' + questionType + '-input').val(value);
            toggleNextButton(questionId);
        }

        // Set answer of a question as null (user clicked on "I dont want to answer") :
        // - set all question answer as inactive
        // - set null value (-1) in hidden inputs
        function setAnswerAsNull(questionId) {

            // set all question answers as inactive for a question type (do/think)
            setAnswerInactive(questionId, TYPE_THINK);
            setAnswerInactive(questionId, TYPE_DO);

            // unchecked answer using template type 2
            $('.js-question-' + questionId + '-answers-think').prop('checked', false);
            $('.js-question-' + questionId + '-answers-do').prop('checked', false);

            // clear stored values for current question and its type (do/think)
            storeAnswerValue(questionId, TYPE_THINK, VALUE_NULL);
            storeAnswerValue(questionId, TYPE_DO, VALUE_NULL);
        }

        // Set answer of a question as null (user clicked on "I dont want to answer") :
        // - set all question answer as inactive
        // - remove stored values in hidden inputs
        function clearAnswer(questionId) {

            // set all question answers as inactive for a question type (do/think)
            setAnswerInactive(questionId, TYPE_THINK);
            setAnswerInactive(questionId, TYPE_DO);

            // unchecked answer using template type 2
            $('.js-question-' + questionId + '-answers-think').prop('checked', false);
            $('.js-question-' + questionId + '-answers-do').prop('checked', false);

            // clear stored values for current question and its type (do/think)
            storeAnswerValue(questionId, TYPE_THINK, '');
            storeAnswerValue(questionId, TYPE_DO, '');
        }

        // Toggle next button
        function toggleNextButton(questionId) {

            if ($('.js-question-' + questionId + '-' + TYPE_DO + '-input').val().length &&
                $('.js-question-' + questionId + '-' + TYPE_THINK + '-input').val().length) {

                // enable next button
                $('.js-question-' + questionId + '-next').removeClass('disabled');
            } else {
                // disable next button
                $('.js-question-' + questionId + '-next').addClass('disabled');
            }
        }

        function rangeChange($rangeElement) {

            const questionId = $rangeElement.data('question'),
                value = $rangeElement.val(),
                questionType = $rangeElement.data('type');

            // uncheck "dont want to answer" if user change range value
            $('.js-question-' + questionId + '-null').prop('checked', false);

            storeAnswerValue(questionId, questionType, value);
            updateRangeImage(questionId, questionType, value);
        }

        function updateRangeImage(questionId, questionType, value) {
            $('.js-question-' + questionId + '-range-' + questionType + '-img').hide();
            $('.js-question-' + questionId + '-range-' + questionType + '-img[data-value=' + value + ']').show();
        }

        function toggleDisplayAnswers(questionId, display = false) {

            const $questionElement = $('.js-question-' + questionId);

            if (display) {
                $questionElement.find('.question--think .question__answers, .question--do').slideDown(300);
            } else {
                $questionElement.find('.question--think .question__answers, .question--do').slideUp(300);
            }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = survey;
    }

})(window, window.jQuery);

