(function(global, $) {
    'use strict';

    const codes = function() {

        function init() {
            if ($('body').hasClass('student-codes')) {
                window.print();
            }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = codes;
    }

})(window, window.jQuery);

