(function(global, $) {
    'use strict';

    const admin = function() {

        function init() {

            $('.form--sponsor').on('submit', function(){
                var hasErrors = false;

                var $a2lixTranslations = $('.a2lix_translations');
                var $fields = $(this).find('label.required + select, label.required + input, label.required + textarea');

                $a2lixTranslations.find('a').removeClass('pane-error');
                $fields.removeClass('field-error');

                // We check all fields required
                $fields.each(function(){
                    if (!$(this).val()) {
                        $(this).addClass('field-error');
                        hasErrors = true;

                        var $parent = $(this).parents('.tab-pane');
                        if ($parent.length) {
                            var id = $parent.attr('id');
                            $a2lixTranslations.find('a[href="#'+ id +'"]').addClass('pane-error')
                        }
                    }
                });

                return !hasErrors;
            });

            if (!$('.collection').length) {
                return;
            }

            $('.collection').collection();
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = admin;
    }

})(window, window.jQuery);

