/* global jQuery */

'use strict';

(function (global) {
    global.$ = global.jQuery = require('jquery');
    require('jquery-ui-sortable-only/jquery-ui.min');
    require('symfony-collection/jquery.collection');
    require('bootstrap/dist/js/bootstrap');
    require('chart.js/dist/Chart.min');
    require('slick-carousel/slick/slick.min');
    global.select2 = require('select2/dist/js/select2.min');

    //app modules declarations
    const modules = [];
    modules.push(require('module.admin'));
    modules.push(require('module.adminQuestionnaire'));
    modules.push(require('module.codes'));
    modules.push(require('module.form'));
    modules.push(require('module.survey'));
    modules.push(require('module.surveyConclusion'));
    modules.push(require('module.tips'));
    modules.push(require('module.stats'));
    modules.push(require('module.unload'));
    modules.push(require('module.tool'));
    modules.push(require('module.home'));
    modules.push(require('module.userSearch'));
    modules.push(require('module.folder'));
    modules.push(require('module.password'));

    $(global.document).ready(function () {
        $.each(modules, function (i, module) {
            if (typeof module.ready !== 'undefined') {
                module.ready();
            }
        });
    });

    $(global).on('load',function () {
        $.each(modules, function (i, module) {
            if (typeof module.load !== 'undefined') {
                module.load();
            }
        });
    });

})(window);
