(function(global, $) {
    'use strict';

    const surveyConclusion = function() {

        function init() {
            if ($('.btn-print').length) {
                $('.btn-print').on('click', function() {
                    window.print();
                });
            }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = surveyConclusion;
    }

})(window, window.jQuery);

