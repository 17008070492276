(function(global, $) {
    'use strict';

    const unload = function() {

        function init() {
            if ($('#current-route').length) {
                var currentRoute = $('#current-route').data('current-route');
                if (-1 !== $.inArray(currentRoute, ["survey_questions", 'survey_conclusion'])) {
                    var dialogText = $('#current-route').data('unload-text');
                    window.onbeforeunload = function(e) {
                        // Ignore if event element has class unload-ignore
                        if ($(document.activeElement).length && $(document.activeElement).hasClass('unload-ignore')) {
                            return undefined;
                        }
                        // Only IE support custom message for this event.
                        // See https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
                        e.returnValue = dialogText;
                        return dialogText;
                    };
                }
            }
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = unload;
    }

})(window, window.jQuery);

