(function(global, $) {
    'use strict';

    const home = function() {

        function init() {

            $('.carousel-inner').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                infinite: false,
                dots: true,
                prevArrow: null,
                nextArrow: null
            });

        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = home;
    }

})(window, window.jQuery);
