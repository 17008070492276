(function(global, $) {
    'use strict';

    let $languageSwitcher = $('.language-switcher');

    const $questionThinkTitle = $('.admin-question--think__title.template');
    const $questionDoTitle = $('.admin-question--do__title.template');

    const adminQuestionnaire = function() {

        function init() {

            $('.question-item__label').on('click', function(e){
                e.preventDefault();
            });

            $('.js-language-switcher').on('click', function(){
                const lang = $(this).data('language');

                $languageSwitcher.removeClass('active');
                $(this).addClass('active');

                const $a2lixTranslationsFields = $('.a2lix_translationsFields');
                const $a2lixTranslationsLocales = $('.a2lix_translationsLocales');

                $a2lixTranslationsFields.find('.tab-pane').removeClass('active show');
                $a2lixTranslationsLocales.find('a').removeClass('active');

                $a2lixTranslationsLocales.find('a[href*="_translations_'+ lang +'_a2lix_translations-fields"]').addClass('active');
                $a2lixTranslationsFields.find('.tab-pane[id*="_translations_'+ lang +'_a2lix_translations-fields"]').addClass('active show');
            });

            /**
             * Add collections on questionnaire form
             */
            if (!$('.collection--questions').length) {
                return;
            }

            if ($('.admin-questionnaire--edit').length) {
                initEdit();
            }

            $('.collection--questions').collection({
                prefix: 'question',
                position_field_selector: '.question-order',
                after_add: function(collection, element) {
                    prepareQuestion(element);
                },
                up: '<div style="display:none;"></div>',
                down: '<div style="display:none;"></div>',
                add: '<button type="button" class="btn btn-outline--primary">Ajouter</button>',
                remove: '<button type="button" class="btn btn-outline--danger">Supprimer</button>',
                init_with_n_elements: 1,
                children: [
                    {
                        selector: '.collection--answers-do',
                        prefix: 'answers-do',
                        after_add: function (collection, element) {
                            element.addClass('col-3');
                        },
                        init_with_n_elements: 4,
                        allow_up: false,
                        allow_down: false,
                        allow_add: false,
                        allow_remove: false,
                        allow_duplicate: false,
                        min: 4,
                        max: 4,
                        position_field_selector: '.answer-order'
                    },
                    {
                        selector: '.collection--answers-think',
                        prefix: 'answers-think',
                        init_with_n_elements: 4,
                        allow_up: false,
                        allow_down: false,
                        allow_add: false,
                        allow_remove: false,
                        allow_duplicate: false,
                        after_add: function (collection, element) {
                            element.addClass('col-3');
                        },
                        min: 4,
                        max: 4,
                        position_field_selector: '.answer-order'
                    }
                ]
            });

            var $theme = $('#admin_questionnaire_theme');
            $theme.change(function() {
                var $form = $(this).closest('form');
                var data = {};
                data[$theme.attr('name')] = $theme.val();
                $.ajax({
                    url : $form.attr('action'),
                    type: $form.attr('method'),
                    data : data,
                    success: function(html) {
                        const newPrototype = $(html).find('#admin_questionnaire_questions').data('prototype')
                        const newPrototypeHTML = $.parseHTML(newPrototype);
                        const $categorySelect = $(newPrototypeHTML).find('#admin_questionnaire_questions___questions___category');
                        $form.find('#admin_questionnaire_questions').data('prototype', newPrototype);
                        $('.js-question-category').html($categorySelect.html());
                    }
                });
            });
        }

        function initEdit() {

            const $questions = $('.question-item').parents('fieldset');

            prepareQuestion($questions);

            $('.collection--answers-do, .collection--answers-think').find('> fieldset').addClass('col-3');
        }

        function prepareQuestion($element) {

            const lang = $('.language-switcher.active').data('language');

            $element.find('.a2lix_translationsFields').find('.tab-pane').removeClass('active show');
            $element.find('.a2lix_translationsLocales').find('a').removeClass('active');

            $element.find('.a2lix_translationsLocales').find('a[href*="_translations_'+ lang +'_a2lix_translations-fields"]').addClass('active');
            $element.find('.a2lix_translationsFields').find('.tab-pane[id*="_translations_'+ lang +'_a2lix_translations-fields"]').addClass('active show');

            $element.addClass('d-flex flex-wrap');

            $element.each(function(){
                arrangeFields($(this));
            });
        }

        function arrangeFields($element) {
            // Become think after removing do fields
            const $original = $element.find('> .question-item > .collapse > div > .form-group:nth-child(3)').find('.a2lix_translationsFields');
            const $clone = $original.clone(true);

            $original.find('.tab-pane').each(function(){
                $(this).find('.form-group:nth-child(-n+2)').remove();
            });
            $questionThinkTitle.clone().removeClass('template').prependTo($original);

            $clone.find('.tab-pane').each(function(){
                $(this).find('.form-group:nth-child(n+3)').remove();
            });

            const $do = $clone;
            $questionDoTitle.clone().removeClass('template').prependTo($do);

            $do.insertBefore($element.find('.form-group:nth-child(5)'));
        }

        return {
            ready: init
        };
    }();

    if (typeof module != 'undefined') {
        module.exports = adminQuestionnaire;
    }

})(window, window.jQuery);

